import domReady from '@roots/sage/client/dom-ready';
import $ from 'cash-dom';
import {indexMapComponent} from "@scripts/components/index/map.js";
import {headerComponent} from "@scripts/components/header.js";
import {accordionComponent} from "@scripts/components/accordion.js";
import {qnaComponent} from "@scripts/components/qna.js";
import {tabsComponent} from "@scripts/components/tabs.js";
import {fondsGraphComponent} from "@scripts/components/fonds/graph.js";
import {partnersComponent} from "@scripts/components/partners.js";
import {indexMapServiceComponent} from "@scripts/components/index/map-services.js";
import {nahlaseniePoistnejUdalostiFormComponent} from "@scripts/components/nahlasenie-poistnej-udalosti/form.js";
import {
  nahlasenieZodpovednostiZaSkoduFormComponent
} from "@scripts/components/nahlasenie-poistnej-udalosti/form-zodpovednost-za-skodu.js";
import {newsShortComponent} from "@scripts/components/index/news-short.js";
import {moreInfoComponent} from "@scripts/components/products/more-info.js";
import {cookiesBarComponent} from "@scripts/components/cookies/cookies-bar.js";

/**
 * Application entrypoint
 */
$(document).ready(() => {
  domReady(() => {
    (() => Promise.all([
      headerComponent(),
      indexMapComponent(),
      indexMapServiceComponent(),
      fondsGraphComponent(),
      accordionComponent(),
      tabsComponent(),
      qnaComponent(),
      partnersComponent(),
      moreInfoComponent(),
      nahlaseniePoistnejUdalostiFormComponent(),
      nahlasenieZodpovednostiZaSkoduFormComponent(),
      newsShortComponent(),
      cookiesBarComponent(),
    ].map(promise => promise.catch(console.error))))();
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
