import $ from 'cash-dom';
import {Loader} from '@googlemaps/js-api-loader';
import {mapStyle} from "@scripts/components/index/map-style.js";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {marker} from "../../../../../../../wp-includes/js/codemirror/csslint.js";
import FlexSearch from "flexsearch";
import {debounce} from "@scripts/core.js";

export const indexMapServiceComponent = () => wp.api.loadPromise.done(async () => {
    const loader = new Loader({
      apiKey: 'AIzaSyAc4llZeDKxSq-uCAxkE-Lv3s4QdiVv4CM',
      version: 'weekly',
      region: 'SK',
      language: 'sk',
    });
    const {Map, InfoWindow} = await loader.importLibrary('maps');
    const {AdvancedMarkerElement, PinElement} = await loader.importLibrary('marker');

    const context = {
      fetchId: 0,
    };

    const fetchBranches = async (onUpdate, search = '') => {
      let fetchId = ++context.fetchId;

      const collection = new wp.api.collections.Servisy();
      const result = await collection.fetch({
        data: {
          per_page: 100,
        }
      });

      onUpdate(result, collection.hasMore(), true);
      while (collection.hasMore() && result.length < 200) {
        result.push(...await collection.more());

        if (fetchId !== context.fetchId)
          return undefined;

        onUpdate(result, collection.hasMore(), false);
      }

      return result;
    };

    return Promise.all(
      $('.map-services').get().map(async (root) => {

        const $root = $(root);
        const $mapContainer = $root.find('.map-container');
        const $search = $root.find('.map-search-input');
        const $cards = $root.find('.map-card');
        const $cardTemplate = $root.find('#map-card-template');
        const $contractCards = $root.find('#contract-map-cards');
        const $partnerCards = $root.find('#partner-map-cards');
        let results = [];
        const markers = {};
        const markerResults = {};
        const cards$ = {};
        const position = {lat: 48.74837949102991, lng: 19.21017518456245};
        let zoom = 8;

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition((geoposition) => {
            position.lat = geoposition.coords.latitude
            position.lng = geoposition.coords.longitude;
            zoom = 9;
            console.log('geolocation success', position);
            init();
          }, () => {
            console.log('geolocation error');
            init();
          });
        } else {
          console.log('geolocation disabled');
          init();
        }

        const index = new FlexSearch.Index({
          charset: 'latin:simple',
          tokenize: 'forward',
        });

        const distance = (a) => Math.pow(a.acf['latitude'] - position.lat, 2) + Math.pow(a.acf['longitude'] - position.lng, 2);

        const createCard = (result) => {
          const $card = $cardTemplate.clone();
          $card.attr('id', `map-card-${result.id}`);
          $card.find('.map-card').addClass(result.acf['is_partner'] ? 'border-2 border-transparent' : 'border-2 border-red');
          $card.find('.map-card-title').html(result.title.rendered);
          $card.find('.map-card-address').html(result.acf['address'] + "<br>" + result.acf['zip_code'] + ' ' + result.acf['city'])
          $card.find('.map-card-link').attr('href', result.link)
          $card.find('.phone-link').each((n, el) => {
            const $el = $(el);
            const value = result.acf[n ? 'phone_2' : 'phone'];
            $el.attr('href', `tel:${value}`);
            $el.text(value);
          });

          cards$[result.id] = $card;
          return $card;
        };

        const onUpdate = (newResults, hasMore, isFirst) => {
/*
          if (isFirst)
            clusterer.clearMarkers();
*/

          results = [...newResults];
          results.sort((a, b) => distance(a) - distance(b))
            .forEach((result, n) => {
              if (markers[result.id] || !result.acf['latitude'] || !result.acf['longitude'])
                return;

              const pin = document.createElement('img');
              pin.src = '/wp-content/themes/wushomepage/resources/images/' + (result.acf['is_partner'] ? 'services/pin-2.png' : 'services/pin-1.png');
              pin.style.height = result.acf['is_partner'] ? '40px' : '43px';
              /*
                          const pin = new PinElement({
                            background: result.acf['is_partner'] ? '#E61414' : '#F3F5F7',
                            borderColor: result.acf['is_partner'] ? '#AF1414' : '#D6DDE6',
                            glyphColor: result.acf['is_partner'] ? '#821414' : '#61666C',
                          });
              */
              const marker = new AdvancedMarkerElement({
                position: {
                  lat: result.acf['latitude'],
                  lng: result.acf['longitude'],
                },
                content: pin,
                // content: pin.element,
                map,
              });

              const infowindow = new InfoWindow({
                content: `<strong>${result.title.rendered}</strong><br>
${result.acf['address']}, ${result.acf['city']}<br>
${result.acf['is_partner'] ? 'Partnerský servis' : 'Zmluvný servis'}<br>
<a class="underline hover:no-underline text-red-dark font-medium" target="_blank" href="${result.link}">Detail servisu</a>`
              });
              marker.addListener('click', () => {
                infowindow.open(map, marker);
              });
              markers[result.id] = marker;
              markerResults[result.id] = result;
              index.add(result.id, `${result.acf['city']} ${result.title.rendered} ${result.acf['zip_code']} ${result.acf['address']}`);

              // clusterer.addMarker(marker);

              if (result.acf['is_partner']) {
                $partnerCards.append(createCard(result));
              } else {
                $contractCards.append(createCard(result));
              }
            });
        };

        let map/*, clusterer*/;

        const init = function () {
          console.log('init', position);
          map = new Map($mapContainer.get(0), {
            center: position,
            zoom,
            maxZoom: 15,
            disableDefaultUI: true,
            styles: mapStyle,
            mapId: '7930465941fdbb2d',
          });

          const legend = $root.find('#map-legend-template').get(0);
          legend.remove();
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(legend);
          console.log(legend);

/*
          clusterer = new MarkerClusterer({
            map,
            markers: [],
          });
*/

          fetchBranches(onUpdate, $search.val());

          const onSearch = (e) => {
            const value = e.target.value;
            const bounds = new google.maps.LatLngBounds();

            const searchResults = index.search(value);
            if (value.length >= 3 && searchResults.length) {
              // clusterer.clearMarkers();
              Object.values(markers).forEach(marker => marker.map = null);
              Object.values(cards$).forEach($card => $card.addClass('hidden'));

              searchResults.forEach((id, n) => {
                const result = markerResults[id];
                const marker = markers[id];

                // clusterer.addMarker(marker);
                bounds.extend(marker.position);
                marker.map = map;

                cards$[result.id].removeClass('hidden');
              });

              map.fitBounds(bounds);
            } else {
              // clusterer.clearMarkers();
              Object.values(markers).forEach(marker => marker.map = map);
              Object.values(cards$).forEach($card => $card.removeClass('hidden'));

              results.forEach((result, n) => {
                const marker = markers[result.id];

                if (!marker || !result.acf['latitude'] || !result.acf['longitude'])
                  return;

                // clusterer.addMarker(marker);
                bounds.extend(marker.position);
              });

              map.setCenter(position);
              map.setZoom(zoom)
            }
          };

          $search.on('input', debounce(500, onSearch));
        }
      })
    );
  }
);
