import $ from "cash-dom";
import anime from "animejs";

export const moreInfoComponent = async () => {
  $('.more-info').each((n, el) => {
    const $root = $(el);
    const $labels = $root.find('.more-info-label');
    const $labelsRoot = $root.find('.more-info-labels-root');
    const $activeLabelBg = $root.find('.more-info-active-label-bg');
    const $targets = $root.find('.more-info-item');

    const toggleItem = (event) => {
      const $label = $(event.target);
      const $target = $targets.filter(`#${$label.attr('data-target-id')}`);

      $labels.removeClass('text-red font-semibold').addClass('text-gray font-normal');
      $label.addClass('text-red font-semibold').removeClass('text-gray font-normal');

      $targets.addClass('hidden');
      $target.removeClass('hidden');

      anime({
        targets: $activeLabelBg.get(),
        top: $label.offset().top - $labelsRoot.offset().top,
        duration: 250,
        easing: 'easeOutQuad',
      });
      console.log($label.offset().top);
    };

    $labels.on('mouseenter', toggleItem);
    $labels.on('click', toggleItem);
  });
};
